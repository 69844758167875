import React from 'react';
import { BsGithub, BsLinkedin, BsDiscord } from 'react-icons/bs';

const SocialMedia = () => (
    <div className="app__social">
        <div>
            <BsGithub href={"https://github.com/JamesB2018"} />
        </div>
        <div>
            <BsDiscord />
        </div>
        <div>
            <BsLinkedin href={"https://www.linkedin.com/in/james-brake-70523b86/"} />
        </div>
    </div>
);

export default SocialMedia;