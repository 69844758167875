import React, { useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';


import './Navbar.scss';

const Navbar = () => {
    const [toggle, setToggle] = useState()
    return (
        <nav className="app__navbar">  {/*BEM methodology for clean CSS naming */}
            <div className="app__navbar-logo">

            </div>
            <ul className="app__navbar-links"> {/*Will loop through all of our elements that we need in our navigation bar */}
                {['home', 'about', 'work', 'skills', 'contact'].map((item) => (
                <li className={"app__flex p-text"} key={`link-${item}`}>
                    <div></div>
                    <a href={`#${item}`}>{item}</a>
                </li>
                ))}
            </ul>

            <div className={"app__navbar-menu"}>
                <HiMenuAlt4 onClick={() => setToggle(true)} />

                {toggle && (
                    <motion.div whileInView={{ x: [300, 0] }}
                                transition={{ duration: 0.85, ease: 'easeOut' }}>
                        <HiX onClick={() => setToggle(false)} />
                        <ul>
                        <ul>
                            {['home', 'about', 'work', 'skills', 'contact'].map((item) => (
                                <li key={item}>
                                    <a href={`#${item}`} onClick={() => setToggle(false)}>
                                        {item}
                                    </a>
                                </li>
                            ))}
                        </ul>
                        </ul>
                    </motion.div>
                )}
            </div>
        </nav>
    );
};

export default Navbar